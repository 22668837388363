import React from "react"

const Index = ({ isFirst, isActive, progressLabel, progressStep }) => {
  return (
    <div className="progress-bar-container d-flex flex-column align-item-center">
      {!isFirst && (
        <hr
          className={`progress-hr mr-3 my-auto ${
            isActive ? "active" : "inactive"
          }`}
        ></hr>
      )}
      <label className={`progress-label ${isActive ? "active" : "inactive"}`}>
        {progressLabel}
      </label>
      <div className={`progress-bar-step ${isActive ? "active" : "inactive"}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill={`${isActive ? "#00a650" : "#bcbcbc"}`}
          className="bi bi-check2"
          viewBox="0 0 16 16"
        >
          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
        </svg>
      </div>
    </div>
  )
}

export default Index
